<template>
  <div class="content-box">
    <div class="content-main">
      <ayl-table :table="table"></ayl-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddressList",
  components: {},
  props: {
    dialogParams: Number,
    default: null
  },
  data() {
    return {
      table: {
        api: this.$api.consignOrder_getAllAddressListForPC,
        query: {
          queryContent: null,
        },
        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "联系人/手机号/地区/详细地址；",
          },
        ],
        columns: [
          {
            title: "序号",
            width: "60px",
            align: "center",
            $index: "index",
          },
          {
            title: "小程序用户",
            showTooltip: true,
            key: "vxContacter",
          },
          {
            title: "小程序用户电话",
            key: "vxContacterPhone",
          },
          {
            title: "联系人",
            key: "contacter",
          },
          {
            title: "联系人电话",
            showTooltip: true,
            key: "contacterPhone",
          },
          {
            title: "地区",
            showTooltip: true,
            key: "areaDes",
          },
          {
            title: "详细地址",
            showTooltip: true,
            key: "addressDetail",
          },
          {
            title: "操作",
            width: "90px",
            align: "center",
            render: (h, ctx) => {
              return h("div", [
                h(
                  "el-button",
                  {
                    props: {
                      type: "text",
                    },
                    style: {
                      color: "#0575E6",
                    },
                    on: {
                      click: () => this.selectAddress(ctx),
                    },
                  },
                  "选择"
                ),
              ]);
            },
          },
        ],
      },
    };
  },
  methods: {
    selectAddress(ctx) {
      console.log(this.dialogParams)
      this.$emit("visible", ctx.row,this.dialogParams);
    },
  },
};
</script>

<style lang="scss" scpoed></style>